// YourComponent.tsx (or .jsx)
import React from "react";
import "../annimation.css";

const NameAnnimationComponent = () => {
  const text = "SAINA TCHAAS NU";
  return (
    <div>
      <h1 className="header-text">
        {text.split("").map((char, index) => {
          return (
            <span key={index} style={{ animationDelay: `${index * 0.1}s` }}>
              {char === " " ? "\u00a0" : char}
            </span>
          );
        })}
      </h1>
    </div>
  );
};

export default NameAnnimationComponent;
