import React from "react";
import styled from "styled-components";
import links, { Link as LinkType } from "./Links";
import LinkItem from "./components/Linkitem";
import NameAnnimationComponent from './components/NameAnnimation';
import { Avatar } from "@mui/material";

import './styles.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #C4A484;
`;

const Linktree: React.FC = () => {
  return (
    <Container>
      <NameAnnimationComponent></NameAnnimationComponent>
      <div><p></p></div>
      <Avatar alt="Saina" src="/lasaina_avatar.png" sx={{ width: 125, height: 125 }} />
      <div><p></p></div>
      <div><p></p></div>
      {links.map((link: LinkType) => (
        <LinkItem key={link.id} title={link.title} url={link.url} image={link.image} />
      ))}
    </Container>
  );
};

export default Linktree;
