export interface Link {
  id: number;
  title: string;
  url: string;
  image?: string;
}

const links: Link[] = [
  {
    id: 1,
    title: "Youtube",
    url: "https://www.youtube.com/@lasaina"
  },
  {
    id: 2,
    title: "Tik-Tok",
    url: "https://www.tiktok.com/@lasaina"
  },
  {
    id: 3,
    title: "Instagram",
    url: "https://www.instagram.com/lasaina"
  },
  {
    id: 4,
    title: "Amazon Storefront",
    url: "https://www.amazon.com/shop/influencer-ebb456ae"
  },
  
  {
    id: 5,
    title: "Nuri Nahara",
    url: "https://nurinahara.com"
  },
  {
    id: 6,
    title: "Twitter",
    url: "https://x.com/sainatchaasnu"
  }
];

export default links;
