import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  color: #000000;
  padding: 1rem 2rem;
`;

interface Props {
  title: string;
  url: string;
  image?: string;
}

const LinkItem: React.FC<Props> = ({ title, url, image }) => {
  return (
    <Container>
      <Button variant="contained" color="inherit" href={url} target="_blank" rel="noopener noreferrer" sx={{ width: "250px", background: "#EFD6C4" }}>{title}</Button>
    </Container>
  );
};

export default LinkItem;
